import React, { useEffect, useState, useMemo, ChangeEvent } from 'react'
import { FaRedoAlt, FaSave, FaTrash } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { usePlaylist } from '../../hook'
import { dispatchPlaylistTime, setPlaylistTime, usePlaylistState, usePlaylistTimeSelect } from '../../states'
import { resetHasNewPlaylist, useHasNewPlaylistSelect } from '../../states/useHasNewPlaylist'
import { useMosaicSelect } from '../../states/useMosaicState'
import { addPlaylist, removePlaylist, resetPlaylists, usePlaylistsSelect } from '../../states/usePlaylistsState'
import { millisecondsToTimeString } from '../../utils'
import { IconButton } from '../shared'
import * as S from './styles'

let intervalId: ReturnType<typeof setInterval> | undefined = undefined

export const Playlist = () => {
  const history = useHistory()
  const { nextPlaylist, previousPlaylist, playlistPosition } = usePlaylist()
  const playlistTime = usePlaylistTimeSelect()
  const playlists = usePlaylistsSelect()
  const [runPlaylist, setRunPlaylist] = usePlaylistState()
  const camerasToShow = useMosaicSelect()
  const hasNewPlaylist = useHasNewPlaylistSelect()
  const [editTime, setEditTime] = useState(false)
  const [time, setTime] = useState(playlistTime)

  useEffect(() => {
    if (runPlaylist) intervalId = setInterval(() => dispatchPlaylistTime(prev => prev - 1), 1000)
    if (!runPlaylist) intervalId && clearInterval(intervalId)
    return () => intervalId && clearInterval(intervalId)
  }, [runPlaylist])

  useEffect(() => {
    if (playlistTime <= 0) nextPlaylist()
  }, [nextPlaylist, playlistTime])

  const handleSetTime = () => {
    setEditTime(false)
    setPlaylistTime(time)
  }

  const togglePlaylist = () => {
    !runPlaylist && editTime && handleSetTime()
    setRunPlaylist(!runPlaylist)
  }

  const handleClickCamera = (id: string) => {
    history?.push(`/cameras/camera/${id}`)
  }

  const handleChangeTime = (e: ChangeEvent<HTMLInputElement>) => setTime(Number(e.target.value))

  const handleAddPlaylist = () => {
    addPlaylist(camerasToShow)
    resetHasNewPlaylist()
  }

  const handleRemovePlaylist = () => {
    if (playlists.length === 1) return resetPlaylists()
    removePlaylist(Number(playlistPosition))
    previousPlaylist()
  }

  const handleEditTime = () => {
    setPlaylistTime(time)
    setEditTime(true)
    setRunPlaylist(false)
  }

  const showDeleteButton = useMemo(() => {
    if (playlists.length <= 1) return false
    if (hasNewPlaylist) return false
    return true
  }, [hasNewPlaylist, playlists.length])

  const showRefreshButton = useMemo(() => {
    if (playlists.length > 1) return false
    return true
  }, [playlists.length])

  if (!camerasToShow?.length) return null
  return (
    <S.Container>
      {!editTime && <S.Time onClick={handleEditTime}>{millisecondsToTimeString(playlistTime)}</S.Time>}
      {editTime && <S.EditTime autoFocus onChange={handleChangeTime} value={time} />}
      <S.GhostButton onClick={togglePlaylist} width="100%">
        {runPlaylist && <S.Pause />}
        {!runPlaylist && <S.Play />}
      </S.GhostButton>
      {camerasToShow.map(camera => (
        <S.Thumb
          title={camera.name}
          src={camera.sponsorLogoUrl}
          key={camera._id}
          onClick={() => handleClickCamera(camera._id)}
        />
      ))}
      <S.WrapperButtons>
        <S.GhostButton onClick={previousPlaylist}>
          <S.ChevronsLeft />
        </S.GhostButton>
        <S.GhostButton onClick={nextPlaylist}>
          <S.ChevronsRight />
        </S.GhostButton>
      </S.WrapperButtons>
      {hasNewPlaylist && <IconButton onClick={handleAddPlaylist} mx="auto" icon={FaSave} variant="green" />}
      {showDeleteButton && <IconButton onClick={handleRemovePlaylist} mx="auto" icon={FaTrash} variant="red" />}
      {showRefreshButton && <IconButton onClick={resetPlaylists} mx="auto" icon={FaRedoAlt} variant="blue" />}
    </S.Container>
  )
}
