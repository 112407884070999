import React from 'react'
import * as Styled from './styled'
import ChatContainer from './Chat'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toggleExpandedChat, toggleOpenChat } from '../../redux/chats'

const ChatBar = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const chats = useSelector(c => c.chats.value.filter(c => c.open))

  const toggleExpanded = item => {
    dispatch(toggleExpandedChat(item))
  }

  const toggleOpen = item => {
    dispatch(toggleOpenChat(item))
  }

  return (
    <Styled.Container>
      {chats.slice(0, 5)?.map((item, index) => (
        <ChatContainer
          item={item}
          key={index}
          expanded={item.expanded}
          toggleOpen={() => toggleOpen(item)}
          toggleExpanded={() => toggleExpanded(item)}
          history={history}
        />
      ))}
    </Styled.Container>
  )
}

export default ChatBar
